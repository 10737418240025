import { EventEmitter, Injectable, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PaginationModel, RetriveUserModel } from "@/app/shared/interface/product.interface";
import { CatetoryQueryString, ItemsPageQueryString, LabelQueryString, PageQueryString, QueryString, SearchUserQueryString, SortQueryString, TagQueryString } from "@/app/shared/filter";
import { CommonService } from "./common.service";

@Injectable({ providedIn: 'root' })
export class QueryStringService {
    @Output() eventPagination: EventEmitter<PaginationModel> = new EventEmitter<PaginationModel>();
    @Output() eventChangeQueryString: EventEmitter<any> = new EventEmitter<any>();
    private _queryString: QueryString[] = [];
    public limitNumber: number = 16;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private commonService: CommonService) {
        this.subcribeUrlParams();
    }
    /** Get url query string => our object QueryString */
    private subcribeUrlParams() {
        this.route.queryParams.subscribe(params => {
            this._queryString.length = 0;

            /** TODO: generic concrete of QueryString class */
            if (params['s']) {
                this._queryString.push(new SearchUserQueryString(params['s']));
            }
            if (params['page']) {
                this._queryString.push(new PageQueryString(parseInt(params['page'])));
            }
            if (params['pageSize']) {
                this._queryString.push(new ItemsPageQueryString(parseInt(params['pageSize'])));
            }
            if (params['sortBy']) {
                this._queryString.push(new SortQueryString(params['sortBy']));
            }
            if (params['category']) {
                this._queryString.push(new CatetoryQueryString(params['category']));
            }
            if (params['tag']) {
                this._queryString.push(new TagQueryString(params['tag']));
            }
            if (params['label']) {
                this._queryString.push(new LabelQueryString(params['label']));
            }
        });
    }

    public startFetchingData(): QueryString[] {
        this.eventChangeQueryString.emit(this._queryString);
        return this._queryString;
    }

    public getQueryString(q: QueryString): QueryString {
        for (const i of this._queryString) {
            if (i.fieldName === q.fieldName) {
                return i;
            }
        }
        return q;
    }

    public setQueryString(q: QueryString) {
        let existed = this._queryString.find(c => q.equals(c));
        if (existed) {
            existed.value = q.value;
        }
        else {
            if (!q.isNoValue) {
                this._queryString.push(q);
            }
        }
    }

    private removeQueryString(q: QueryString) {
        let index = this._queryString.findIndex(c => q.equals(c));
        if (index >= 0) {
            this._queryString.splice(index, 1);
        }
    }

    /** Set url query string <= our object QueryString  */
    public buildQueryString() {
        let sortedQuery = this._queryString.sort(s => s.sort);
        let queryParams: Params = {};
        if (sortedQuery.length) {
            sortedQuery.forEach(v => {
                queryParams[v.fieldName] = v.value;
            });
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: queryParams,
                //queryParamsHandling: 'merge', // preserve the existing query params in the route
                skipLocationChange: false,
            });
        }
        else {
            this.router.navigate([], {
                relativeTo: this.route,
                skipLocationChange: false,
            });
        }
    }

    public get queryString() {
        return this._queryString;
    }

    public changePagination(total: PaginationModel) {
        this.eventPagination.emit(total);
    }
    public resetPage() {
        [PageQueryString, ItemsPageQueryString, LabelQueryString, CatetoryQueryString].forEach(q => {
            let index = this._queryString.findIndex(f => f instanceof q);
            if (index >= 0) {
                this._queryString.splice(index, 1);
            }
        });
    }

    public changeQueryString(f: QueryString, resetCurrentPage?: boolean): any;
    public changeQueryString(f: QueryString[], resetCurrentPage?: boolean): any;
    public changeQueryString(f: unknown, resetCurrentPage: boolean = false): any {
        if (resetCurrentPage || f instanceof SearchUserQueryString || f instanceof LabelQueryString || f instanceof CatetoryQueryString) {
            this.resetPage();
        }
        if (f instanceof QueryString) {
            this.setQueryString(f);
        } else if (Array.isArray(f)) {
            for (const i of f) {
                this.setQueryString(i);
            }
        }
        this.buildQueryString();
        this.eventChangeQueryString.emit();
    }
    private defaultLimitPerPage = 20;
    public buildFetchingModel(defaultLimit?: number): RetriveUserModel {
        let f = this.queryString;
        let body: RetriveUserModel = new RetriveUserModel();
        for (const key of Object.keys(body)) {
            let query = f.find(q => q.fieldModelName === key);
            if (query) {
                if (query instanceof PageQueryString) {
                    let limitQuery = f.find(q => q._fieldModelName == 'limit');
                    let limit = limitQuery?.value || this.limitNumber;
                    Object.assign(body, {
                        [query.fieldModelName]: (query.value - 1) * limit
                    });
                } else if (query instanceof CatetoryQueryString) {
                    let slugs = query.value;
                    let categories = this.commonService.productCategories;
                    let ids = this.getCategoryIds(categories, slugs);
                    Object.assign(body, {
                        [query.fieldModelName]: ids
                    });
                } else if (query instanceof TagQueryString || query instanceof LabelQueryString) {
                    let slugs = query.originalValue;
                    Object.assign(body, {
                        [query.fieldModelName]: slugs
                    });
                } else {
                    Object.assign(body, {
                        [query.fieldModelName]: query.value
                    });
                }
            }
        }

        if (!body.limit) {
            body.limit = defaultLimit ?? this.defaultLimitPerPage;
        }

        return body;
    }

    private getCategoryIds(cates: any[], slugs: string): string[] {
        let ids: string[] = [];
        for (const c of cates) {
            if (slugs.includes(c.slug)) {
                ids.push(c.categoryId);
            }
            if (c.children && c.children.length) {
                ids = ids.concat(this.getCategoryIds(c.children, slugs));
            }
        }
        return ids;
    }

    /** Clear all query strings */
    public clearAllQueryStrings() {
        this._queryString.length = 0;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false,
        });
    }
}