
export abstract class QueryString {
    abstract _fieldName: string;
    abstract _fieldModelName: string;
    public isDataFilter: boolean = false;
    protected _value: any;
    sort: number = 0;

    public get fieldName(): string {
        return this._fieldName;
    }

    public abstract get isNoValue(): boolean;

    public get fieldModelName(): string {
        return this._fieldModelName;
    }

    public equals(target: QueryString): boolean {
        return this._fieldName === target._fieldName;
    }

    public get originalValue(): any{
        return this._value;
    }

    public get value(): any {
        return this._value;
    }

    public set value(value: any) {
        this._value = value;
    }

    public removeValue(value: any){
        this._value = undefined;
    }
}