import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Cart, CartItem } from '../interface/cart.interface';
import { DosageForm, Product } from '../interface/product.interface';
import { Province } from '../interface/province.interface';
import { Tag } from '../interface/tag.interface';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  public showLoader: boolean = false;

  public authEvent: EventEmitter<boolean> = new EventEmitter();

  private productDetail = new BehaviorSubject<Product>(new Product());
  public getProductDetail = this.productDetail.asObservable();
  setProductDetail(product: Product) {
    this.productDetail.next(product);
  }

  private cart = new BehaviorSubject<any>(null);
  public getCart = this.cart.asObservable();
  setCart(cart: any) {
    this.cart.next(cart);
  }

  private cartItem = new BehaviorSubject<any>(null);
  public getCartItem = this.cartItem.asObservable();
  setCartItem(cartItem: CartItem) {
    this.cartItem.next(cartItem);
  }

  private _productCategories: any[];
  public get productCategories(): any[] {
    return this._productCategories;
  }

  private _productTags: Tag[];
  public get productTags(): Tag[]{
    return this._productTags;
  }

  private categories = new BehaviorSubject<any>(null);
  public getCategories = this.categories.asObservable();
  setCategories(categories: any) {
    this._productCategories = categories;
    this.categories.next(categories);
  }
  private subProductTags = new BehaviorSubject<any>(null);
  public getProductTags = this.subProductTags.asObservable();
  setProductTags(tags: Tag[]){
    this._productTags = tags;
    this.subProductTags.next(tags);
  }

  private category = new BehaviorSubject<any>(null);
  public getCategory = this.category.asObservable();
  setCategory(category: any) {
    this.category.next(category);
  }

  private showLoginPopup = new BehaviorSubject<boolean>(false);
  public getShowLoginPopup = this.showLoginPopup.asObservable();
  setShowLoginPopup(value: boolean) {
    this.showLoginPopup.next(value);
  }

  private provinces = new BehaviorSubject<Province[]>([]);
  public getProvinces = this.provinces.asObservable();
  setProvinces(provinces: Province[]) {
    this.provinces.next(provinces);
  }

  private wishlist = new BehaviorSubject<number[]>([]);
  public getWishlist = this.wishlist.asObservable();
  setWishlist(wishlist: number[]) {
    this.wishlist.next(wishlist);
  }

  private title = new BehaviorSubject<string>("");
  public getTitle = this.title.asObservable();
  setTitle(title: string) {
    this.title.next(title);
  }

  private mobileMenu = new BehaviorSubject<boolean>(false);
  public getOpenMobileMenu = this.mobileMenu.asObservable();
  setOpenMobileMenu(value: boolean) {
    this.mobileMenu.next(value);
  }

  getPager(totalItems: number, currentPage: number, pageSize: number, paginateRange: number) {

    // calculate total pages
    let totalPages = Number(Math.ceil(Number(totalItems) / Number(pageSize)));

    // Paginate Range


    // ensure current page isn't out of range
    if (Number(currentPage) < 1) {
      currentPage = 1;
    } else if (Number(currentPage) > Number(totalPages)) {
      currentPage = Number(totalPages);
    }

    let startPage: number, endPage: number;
    if (Number(totalPages) <= Number(paginateRange)) {
      // Less than or equal to the paginateRange
      startPage = 1;
      endPage = Number(totalPages);
    } else if (Number(currentPage) <= Number(Math.floor(Number(paginateRange) / 2))) {
      // Near the beginning
      startPage = 1;
      endPage = Number(paginateRange);
    } else if (Number(currentPage) >= Number(totalPages) - Number(Math.floor(Number(paginateRange) / 2))) {
      // Near the end
      startPage = Number(totalPages) - Number(paginateRange) + 1;
      endPage = Number(totalPages);
    } else {
      // In the middle
      startPage = Number(currentPage) - Number(Math.floor(Number(paginateRange) / 2));
      endPage = Number(currentPage) + Number(Math.floor(Number(paginateRange) / 2));
    }

    // calculate start and end item indexes
    let startIndex = (Number(currentPage) - 1) * Number(pageSize);
    let endIndex = Math.min(Number(startIndex) + Number(pageSize) - 1, Number(totalItems) - 1);


    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((Number(endPage) + 1) - Number(startPage)).keys()).map(i => Number(startPage) + Number(i));

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getDosageForm(value: DosageForm) {
    switch (value) {
      case DosageForm.Other:
        return "Khác";
      case DosageForm.Solution:
        return "Dung dịch";
      case DosageForm.Tablet:
        return "Viên nang cứng";
      case DosageForm.EffervescentTablet:
        return "Viên sủi bọt";
      case DosageForm.Powder:
        return "Bột";
      case DosageForm.Capsule:
        return "Viên con nhộng";
      case DosageForm.Injection:
        return "Tiêm";
      default:
        return "";
    }
  }

  /**
   * @param {int} The month number, 0 based
   * @param {int} The year, not zero based, required to account for leap years
   * @return {Date[]} List with date objects for each day of the month
  */
  getDaysInMonthUTC(month: number, year: number) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  /**
   * Parse JWT
   * @param token 
   * @returns json object
  */
  parseJwt (token: string) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }
    catch {
      return null;
    }
  }

  getCookie(cookies: string, key: string) {
    var ca = cookies?.split(';');
    if (ca) {
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        if (c.trim().indexOf(key) != -1) {
          var cc = c.split('=');
          return cc[1];
        }
      }
    }
    return "";
  }
}
