import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Paginate } from '@/app/shared/interface/pagination.interface';
import { CommonService } from '@/app/shared/services/common.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() public total: number;
  @Input() public currentPage: number;
  @Input() public pageSize: number;
  @Input() public paginateRange: number = 7;

  @Output() setPage: EventEmitter<number> = new EventEmitter();

  public paginate: Paginate; // Pagination use only

  constructor(
    private commonService: CommonService) { }

  // Detect changes
  ngOnChanges(changes: SimpleChanges) {
    this.total = changes['total'] ? changes['total'].currentValue : this.total;
    this.currentPage = changes['currentPage'] ? changes['currentPage'].currentValue : this.currentPage;
    this.pageSize = changes['pageSize'] ? changes['pageSize'].currentValue : this.pageSize;
    // // Get Pager For Pagination
    this.paginate = this.commonService.getPager(this.total, this.currentPage, this.pageSize, this.paginateRange);
  }

  // Set Page
  pageSet(page: number) {
    this.setPage.emit(page);  // Set Page Number  
  }

  onPageChanged(e: any) {
    if (e.target.value != "") {
      this.currentPage = Number(e.target.value);
    }
    else {
      this.currentPage = 1;
    }
    this.setPage.emit(this.currentPage);
  }

}
