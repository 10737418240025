import { PaginateModel } from './core.interface';
import { Attachment } from './attachment.interface';
import { Category } from './category.interface';
import { Tag } from './tag.interface';
import { CartItem } from './cart.interface';

// export interface ProductModel extends PaginateModel {
//     data: Product[];
// }

export interface ProductModel {
  products: Product[];
  total: number;
}

export interface IngredientModel {
  ingredient: Ingredient[];
  total: number;
}

export class Ingredient {
  name: string;
  id: string;
  slug: string;
}

export class Product {
  id: string;
  productID: number;
  name: string;
  slug: string;
  short_description: string;
  description: string;
  ingredients: string;
  type: string;
  imageUrls: string[];
  unit: string;
  volume: string;
  weight: number;
  basePrice: number;
  pricingTablePrice: number;
  flashSalePrice: string | null;
  dosageForm: DosageForm;
  sku: string;
  stockStatus: StockStatus;
  manufacturer: Manufacturer;
  quantityAvailable: number;
  status: boolean;
  metaTitle: string;
  metaDescription: string;
  metaImages: string[] = [];
  tags: Tag[];
  labels: Tag[];
  categories: Category[];
  productVariantIds: number[];
  additionalInfo: AdditionalInfo[] = [];
  productInfo: ProductInfo[] = [];
  SKU: string;
  wholesalePriceInfo: WholesalePriceInfo;
  cartItem: CartItem;
  giftExchangeInfo: GiftExchangeInfo;
  wholesalePrices: WholesalePriceInfo[] = [];
}

export class WholesalePriceInfo {
  minQuantity: number;
  wholesalePrice: number;
}

export class ProductInfo {
  name: string;
  value: string;
  type: ProductInfoType;
  path?: string;
  display: boolean = true;
}

export class AdditionalInfo {
  name: string;
  value: string;
  display: boolean = true;
}

export interface Manufacturer {
  code: string;
  name: string;
}

export enum DosageForm {
  Other = 0,
  Solution = 1,
  Tablet = 2,
  EffervescentTablet = 3,
  Powder = 4,
  Capsule = 5,
  Injection = 6,
}

export enum StockStatus {
  OutOfStock = 0,
  OnHand = 1, //the quantity of goods available for sale
  HandOuted = 2, //quantity of goods for which there is demand but no ready availability
  NotAvailable = 3, //products that are unavailable at present
  MadeToOrder = 4, //quantity of products available within a week based on orders received
  ReadyToShip = 5, //product quantity available for shipping
  Discontinued = 6, //the products that won’t be manufactured once the current stock finishes
  TempporarilyUnavailable = 7, //products unavailable as of now, but for which backorders will be taken when they become available
  NotAllowedToViewPrice = 8, //products that are not allowed to view price
  NotAllowedToOrder = 9, //products that are not allowed to order
}

export enum ProductInfoType {
  Categories = 0,
  Dosage = 1,
  Unit = 2,
  Branch = 3,
  Manufacturer = 4,
  RegistrationNo = 5,
}

export class RetriveUserModel {
  searchTerm?: string = '';
  offset?: number = 0;
  limit?: number;
  sortBy?: string = '';
  category?: string = '';
  categories?: string[] = [];
  tags?: string[] = [];
  labels?: string[] = [];
  productIds?: number[];
}
export class PaginationModel extends RetriveUserModel {
  totalItems: number;
}
export class ProductsPaginationModel {
  products: Product[] = [];
  pagination: PaginationModel = new PaginationModel();
  total: number = 0;
}
export class IngredientPaginationModel {
  ingredient: Ingredient[] = [];
  pagination: PaginationModel = new PaginationModel();
  total: number = 0;
}

export interface GiftExchangeInfo {
  cost: number;
}