import { QueryString } from "@/app/shared/filter";

export class PageQueryString extends QueryString {
    public override get isNoValue(): boolean {
        return this._value === 0;
    }
    override _fieldModelName: string = "offset";
    override _fieldName: string = "page";
    constructor(value: number = 0, sort: number = 0) {
        super();
        this._value = value;
    }
}