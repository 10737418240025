import { QueryString } from "@/app/shared/filter";

export class LabelQueryString extends QueryString {
    public override get isNoValue(): boolean {
        return this._value.length === 0;
    }
    override _fieldModelName: string = "labels";
    override _fieldName: string = "label";
    public override isDataFilter: boolean = true;
    constructor(category: string = "", sort: number = 0) {
        super();
        this._value = [];
        if (category === '') {
            this._value.length = 0;
        } else {
            let split = category.split(',');
            split = split.filter((v: string) => v !== '');
            this._value.push(...split);
        }
    }

    public override get originalValue(): string[] {
        return this._value;
    }

    public override get value(): any {
        return this._value.map((v: string) => v).join(',');
    }

    public override set value(v: string) {
        let split = v.split(',');
        this._value.length = 0;
        this._value = split.filter((s: string) => s !== '');
    }

    public override removeValue(value: string) {
        this._value = this._value.filter((v:string) => v !== value);
    }
}