<div class="paging">
  <div style="display: flex; align-items: center; gap: 8px; z-index: 1">
    Đi đến trang:
    <input
      class="form-control"
      style="max-width: 60px; padding: 8px; text-align: center"
      [value]="paginate.currentPage"
      (change)="onPageChanged($event)"
    />
    / {{ paginate.totalPages }}
  </div>
  <ul
    class="pagination justify-content-center"
    *ngIf="paginate.pages && paginate.pages.length"
  >
    <li class="page-item" [ngClass]="{ disabled: paginate.currentPage === 1 }">
      <button
        type="button"
        class="page-link"
        tabindex="-1"
        aria-disabled="true"
        (click)="pageSet(1)"
      >
        <i class="ri-arrow-left-double-line"></i>
      </button>
    </li>
    <li class="page-item" [ngClass]="{ disabled: paginate.currentPage === 1 }">
      <button
        class="page-link"
        tabindex="-1"
        aria-disabled="true"
        (click)="pageSet(paginate.currentPage - 1)"
      >
        <i class="ri-arrow-left-s-line"></i>
      </button>
    </li>
    <li
      class="page-item number"
      *ngFor="let page of paginate.pages"
      [ngClass]="{ 'active disabled': paginate.currentPage == page }"
      style="opacity: 1"
    >
      <button class="page-link" (click)="pageSet(page)">{{ page }}</button>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }"
    >
      <button class="page-link" (click)="pageSet(paginate.currentPage + 1)">
        <i class="ri-arrow-right-s-line"></i>
      </button>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }"
    >
      <button class="page-link" (click)="pageSet(paginate.totalPages!)">
        <i class="ri-arrow-right-double-line"></i>
      </button>
    </li>
  </ul>
</div>
