<section class="breadcrumb-section pt-0" *ngIf="breadcrumb">
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain">
                    <!-- <h2 class="">{{ breadcrumb.title | titleCase }}</h2> -->
                    <!-- <nav> -->
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item">
                                <a class="d-flex align-items-center" [routerLink]="['/']"><img style="width: 1rem;" src="assets/svg/home-icon.svg" /></a>
                            </li>
                            <li class="breadcrumb-item" [class.active]="item?.active" *ngFor="let item of breadcrumb?.items">
                                {{ item?.label! | titleCase }}
                            </li>
                        </ol>
                    <!-- </nav> -->
                </div>
            </div>
        </div>
    </div>
</section>