<nav class="custome-pagination" *ngIf="pagination && pagination.pageSize">
  <app-pagination
    [total]="pagination.totalItems"
    [currentPage]="pagination.currentPage"
    [pageSize]="pagination.pageSize"
    [paginateRange]="pagination.paginateRange!"
    (setPage)="setPaginate($event)"
  >
  </app-pagination>
</nav>
