import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { Paginate } from '@/app/shared/interface/pagination.interface';
import { Subscription } from 'rxjs';
import { ItemsPageQueryString, PageQueryString } from '@/app/shared/filter';
import { PaginationModel } from '@/app/shared/interface/product.interface';
import { QueryStringService } from '@/app/shared/services/querystring.service';

@Component({
  selector: 'app-collection-paginate',
  templateUrl: './collection-paginate.component.html',
  styleUrls: ['./collection-paginate.component.scss']
})
export class CollectionPaginateComponent {

  @Input() filter: any;

  @Output() changePage: EventEmitter<any> = new EventEmitter();

  public pagination: Paginate;
  _paginateRange: number = 7;
  public limitNumber: number = 12;
  private eventTotalItemsSubcription?: Subscription;
  private eventChangeQueryStringSubcription?: Subscription;

  public pageQuery: PageQueryString = new PageQueryString(1);
  public itemsPageQuery: ItemsPageQueryString = new ItemsPageQueryString(this.limitNumber);
  totalItems: number = 0;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,
    private queryStringService: QueryStringService) {
    this.eventChangeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.pageQuery = this.queryStringService.getQueryString(new PageQueryString(1));
      this.itemsPageQuery = this.queryStringService.getQueryString(new ItemsPageQueryString(this.limitNumber));
      this.updatePagination();
    });
    this.eventTotalItemsSubcription = this.queryStringService.eventPagination.subscribe((data: PaginationModel) => {
      this.totalItems = data.totalItems;
      this.pageQuery.value = !data.offset || !data.limit ? 1 : Math.floor(data.offset! / data.limit!) + 1;
      this.itemsPageQuery.value = data.limit;
      this.updatePagination();
    });
  }

  ngOnInit() {

  }

  updatePagination() {
    this.pagination = {
      totalItems: this.totalItems,
      currentPage: this.pageQuery.value,
      pageSize: this.itemsPageQuery.value,
      paginateRange: this._paginateRange,
    };
  }

  setPaginate(pageSelected: number) {
    if (pageSelected) {
      this.pageQuery.value = pageSelected;
    }
    this.queryStringService.changeQueryString([this.pageQuery, this.itemsPageQuery]);
  }

  ngOnDestroy() {
    this.eventTotalItemsSubcription?.unsubscribe();
    this.eventChangeQueryStringSubcription?.unsubscribe();
  }
}
